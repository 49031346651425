.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);

  &--open {
    display: flex;
  }

  .modal__wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    background: white;
    min-height: 20vh;
    max-height: 80vh;
    width: 100%;
    max-width: 1280px;

    .modal__close {
      position: absolute;
      font-size: 0.8em;
      top: -6em;
      right: 0em;
      background: none;
      border: 1px solid #FFF;
      color: white;
      cursor: pointer;

      &:hover {
        color: var(--secondary);
        border-color: var(--secondary);
      }
    }
  }

}