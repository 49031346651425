@import "./reset.css";

body {
  background: #FFF;
  color: #333;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  word-break: break-word;
}

a {
  text-decoration: none;
  color: var(--primary);

  &:hover {
    color: var(--secondary);
  }
}

:root {
  --primary: #3e4fa8;
  --secondary: #536be2;
}

button {
  background-color: white;
  border: 1px solid #CCC;
  border-radius: 1em;
  padding: 1em;
  cursor: pointer;
  margin-top: 2em;

  &:hover {
    background: var(--primary);
    color: white;
  }
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 1em;
    max-width: 15em;

    img {
      width: 400px;
      max-width: 70%;
    }
  }
}

.player {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em auto;
  flex-wrap: wrap;
  max-width: 90%;

  .rhap_container {
    min-width: auto;
    width: 100%;
    max-width: 500px;
    background: white;
    border-bottom: 8px solid #3a50a3;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    padding: 2em;

    text-align: center;

    audio {
      width: 100%;
    }

    @media (max-width: 480px) {
      padding: 1em;
    }
  }

  .rhap_controls-section {
    flex-wrap: wrap;
    justify-content: center;
  }

  .rhap_header {
    padding: 1em;
    background: var(--primary);
    color: #3e4fa8;
    font-weight: 100;

    span {
      font-weight: 500;
    }
  }

  .rhap_play-pause-button {
    width: 5em;
    height: 3em;
    max-width: 100%;

    svg {
      width: 100%;
      height: 100%;
      color: var(--primary);
    }
  }

  .rhap_additional-controls {
    display: none;
  }

  .rhap_main-controls {
    flex: initial;
    max-width: 30%;
    flex-basis: 30%;
    width: 30%;

    @media (max-width: 480px) {
      max-width: 100%;
      flex-basis: 100%;
      width: 100%;
    }
  }

  .rhap_volume-controls {
    flex: initial;
    max-width: 70%;
    flex-basis: 70%;
    width: 70%;

    .rhap_volume-button {
      color: var(--primary);
    }

    .rhap_volume-indicator {
      background: var(--primary);
    }

    @media (max-width: 480px) {
      display: none;
    }
  }

  .rhap_volume-container {
    flex: 100%;
    width: 100%;
    max-width: 100%;

    .rhap_volume-indicator {
      margin-left: -12px;
    }
  }
}

.message {
  padding-top: 2em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 1em auto;
  color: #333;
  text-align: center;

  h3 {
    font-weight: 900;
    font-size: 42px;
  }

  h4 {
    font-weight: 500;
    font-size: 28px;
    padding: 0.5em;
  }
}


.ad-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: auto;
}

.copytext {
  text-align: center;
  padding: 1em;
}

.chat {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}